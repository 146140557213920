import { ArrowBack } from '@mui/icons-material';
import {
  AppBar,
  GlobalStyles,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Box, Container, styled } from '@mui/system';
import Head from 'next/head';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'components/Layout/theme';
import { OnboardingPersist, useOnboarding } from './Router';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: 288,
  borderRadius: 50,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgba(0, 91, 151, 1)'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 50,
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(76, 175, 80, 1)'
        : 'rgba(76, 175, 80, 1)'
  }
}));

const ProgressBar = () => {
  const { onboardingData } = useOnboarding();
  return (
    <>
      <BorderLinearProgress
        sx={{
          height: {
            xs: 20,
            xl: 32
          },
          borderWidth: 4,
          borderStyle: 'solid',
          borderColor: 'transparent',
          '& .MuiLinearProgress-bar': {
            height: {
              xs: 13,
              xl: 25
            }
          }
        }}
        variant="determinate"
        value={(onboardingData.step / onboardingData.total) * 100}
      />
    </>
  );
};

export function OnboardingLayout() {
  const nav = useNavigate();
  const location = useLocation();
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <OnboardingPersist>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
<!-- Meta Pixel Code -->
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '3729908833952074');
fbq('track', 'PageView');
<!-- End Meta Pixel Code -->
      `
          }}
        ></script>
        <noscript>
          <img
            height="1"
            width="1"
            style={{
              display: 'none'
            }}
            src="https://www.facebook.com/tr?id=3729908833952074&ev=PageView&noscript=1"
          />
        </noscript>
      </Head>
      <GlobalStyles
        styles={{
          body: {
            overflowX: 'hidden',
            backgroundColor: matches ? '#FAFAFA' : '#ffffff'
          }
        }}
      />
      <AppBar position="fixed">
        <Container
          maxWidth="xl"
          sx={{
            maxWidth: {
              xl: 1525
            }
          }}
        >
          <Toolbar
            variant="dense"
            sx={{
              height: {
                xl: 82
              }
            }}
          >
            {location.pathname !== '/' ? (
              <>
                <IconButton
                  onClick={() => {
                    nav(-1);
                  }}
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <ArrowBack />
                </IconButton>
                <Box sx={{ display: 'flex', flex: 1 }}>
                  <Box
                    src="/images/logo_top_header.png"
                    component="img"
                    sx={{
                      height: 35
                    }}
                  />
                </Box>
                <ProgressBar />
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box
                  src="/images/logo_top_header.png"
                  component="img"
                  sx={{
                    height: 35
                  }}
                />
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={[
          theme.mixins.toolbar,
          {
            minHeight: {
              xl: 97
            }
          }
        ]}
      />
      <Box
        sx={{
          mt: -4,
          '&::before': {
            position: 'absolute',
            content: '" "',
            backgroundColor: {
              md: '#003077',
              xs: '#ffffff'
            },
            height: '470px',
            left: 'calc(((100vw - (100%)) /2) * -1 )',
            width: '100vw',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `url(/images/background-rapid.png)`,
            backgroundPosition: 'left'
          }
        }}
      />
      <Box position="relative" sx={{ pb: { xs: 0, md: 30 } }}>
        <Outlet />
        <Box
          position="absolute"
          bottom={15}
          width={'100%'}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={6}
        >
          <Box
            sx={{ objectFit: 'contain' }}
            component="img"
            src="/images/equal-housing-opportunity-logo.png"
            width={45}
            height={45}
          />
          <Typography variant="subtitle1">NMLS# 33041</Typography>
        </Box>
      </Box>
    </OnboardingPersist>
  );
}
