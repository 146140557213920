import { Button, Slider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';
import { bankruptcy } from './constants';

function valueText(value: number) {
  return `$ ${value}`;
}
export function Bankruptcy({ step }) {
  const { updateOnboardingData, onboardingData } = useOnboarding();
  const { t } = useTranslation();
  const defaultValue = bankruptcy.findIndex(
    (m) => onboardingData.bankruptcy === m.value
  );
  const [index, setIndex] = useState(defaultValue === -1 ? 0 : defaultValue);
  const nav = useNavigate();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  return (
    <OnboardingModal>
      <OnboardingHeader title={t('rate-quote.long_ago_bankruptcy')} />
      <Typography mb={10} variant="h4" textAlign="center" color="primary">
        {t(bankruptcy[index]?.label)}
      </Typography>
      <Slider
        sx={{ mt: 2, mb: 10 }}
        valueLabelFormat={(va) => {
          return bankruptcy[va]?.label;
        }}
        min={0}
        onChange={(ev, va) => {
          setIndex(va as number);
        }}
        value={index}
        max={8}
        getAriaValueText={valueText}
      />
      <Box sx={{ width: 240, mx: 'auto' }}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="success"
          onClick={() => {
            updateOnboardingData({
              bankruptcy: bankruptcy[index]?.value
            });
            if (onboardingData.hasBankruptcyOrFloreclosure === 'both') {
              nav('../foreclosure');
            } else {
              nav('../property-address');
            }
          }}
        >
          {t('rate-quote.next')}
        </Button>
      </Box>
    </OnboardingModal>
  );
}
