export const layoutStyles = {
  admin: {
    backgroundColor: {
      xs: 'white',
      md: 'background.default'
    }
  },
  usersTable: {
    mt: {
      md: '-14px'
    },
    '& > div': {
      mx: {
        md: '12px'
      },
      mt: {
        md: '24px',
        xs: '12px'
      }
    }
  },
  logsTable: {
    mt: {
      md: '-14px'
    },
    '& > div': {
      mx: {
        md: '12px'
      },
      mt: {
        md: '24px',
        xs: '67px'
      }
    }
  },
  myLoanPage: {
    mt: {
      md: '0'
    },
    '& > div': {
      mx: {
        md: '0'
      },
      mt: {
        md: '0',
        xs: '67px'
      }
    }
  }
};
