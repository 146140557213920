import { useEffect } from 'react';
import { Outlet } from 'react-router';
import { useOnboarding } from './Router';

export function ProgressWrapperRoute({ total }) {
  const { updateOnboardingData } = useOnboarding();
  useEffect(() => {
    updateOnboardingData({ total });
  }, [total, updateOnboardingData]);

  return (
    <>
      <Outlet />
    </>
  );
}
