import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { TextInput } from 'components/TextInput/TextInput';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

const formSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
    .min(2)
    .max(40)
    .label('First Name')
    .required(),
  lastName: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
    .min(2)
    .label('Last Name')
    .max(40)
    .required()
});

export function PersonalInfoName({ step }) {
  const { updateOnboardingData, onboardingData } = useOnboarding();
  const nav = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  return (
    <OnboardingModal maxWidth={1000}>
      <OnboardingHeader
        mb={{
          xs: 4,
          sm: 13
        }}
        marginWithDescription={7.5}
        title={t('rate-quote.name')}
        description={t('rate-quote.name_description')}
      />
      <Formik
        initialValues={{
          firstName: onboardingData.firstName,
          lastName: onboardingData.lastName
        }}
        validationSchema={formSchema}
        onSubmit={({ firstName, lastName }) => {
          updateOnboardingData({
            firstName,
            lastName
          });
          nav('../email');
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          submitForm,
          isValid,
          errors
        }) => (
          <>
            <TextInput
              variant="outlined"
              placeholder="First Name"
              size="medium"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              helperText={errors.firstName}
              error={!!errors.firstName}
              onBlur={handleBlur}
            />
            <Box mt={6}>
              <TextInput
                variant="outlined"
                placeholder="Last Name"
                size="medium"
                name="lastName"
                value={values.lastName}
                helperText={errors.lastName}
                error={!!errors.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box sx={{ width: 240, mx: 'auto', mt: 14 }}>
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="success"
                disabled={!isValid}
                onClick={submitForm}
              >
                {t('rate-quote.next')}
              </Button>
            </Box>
          </>
        )}
      </Formik>
    </OnboardingModal>
  );
}
