import axios from 'axios';

type RequestType = {
  amount: number;
  purpose: 0 | 1;
  downPayment?: number;
  fico?: number;
  officer?: string;
  propertyState?: string;
  propertyZip?: string;
  occupancy?: 'OO' | '2ND' | 'INV';
  va?: boolean;
};
export const getPricings = (params: RequestType) =>
  axios.get('/prospects/public-options', {
    baseURL: process.env.NEXT_PUBLIC_BACKEND_ENDPOINT,
    params,
    headers: {
      'X-API-KEY': process.env.NEXT_PUBLIC_API_KEY
    }
  });

export const submitProspect = (data: any) =>
  axios.post('/prospects/submit', data, {
    baseURL: process.env.NEXT_PUBLIC_BACKEND_ENDPOINT,
    headers: {
      'X-API-KEY': process.env.NEXT_PUBLIC_API_KEY
    }
  });
