import currency from 'currency.js';
import { USD_SYMBOL } from 'components/TextInput/Currency';

export default function formatCurrency(value: number | string): string {
  return currency(value, {
    symbol: USD_SYMBOL
  }).format();
}

export function formatAmount(value: number | string): string {
  return currency(value, {
    symbol: ''
  }).format();
}
