/* eslint-disable complexity */
import { Box, Typography, Grid, Radio, FormControlLabel } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import formatCurrency, { formatAmount } from 'utils/formatCurrency';

export function RadioRegularBoxPrice({
  innerMt = 3,
  py = 0,
  label = '',
  value,
  title,
  monthlyPayment,
  rate,
  apr,
  fees,
  points = undefined,
  mt = 12
}) {
  const { t } = useTranslation();
  return (
    <>
      <Box mt={mt}>
        <Box
          bgcolor="primary.main"
          sx={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8
          }}
        >
          <Typography
            py={py}
            ml={2}
            sx={{
              fontSize: '16px !important',
              fontWeight: '700 !important'
            }}
            textAlign={points !== undefined ? 'center' : 'left'}
            variant="body1"
            color="white"
          >
            {title}
          </Typography>
        </Box>
        <Box
          bgcolor={points !== undefined ? '#4FA5DF14' : 'action.selected'}
          mt={innerMt}
          px={points !== undefined ? 3 : 6}
        >
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <Typography
                variant="subtitle1"
                minHeight={50}
                sx={{
                  color:
                    points !== undefined ? '#4CAF50 !important' : undefined,
                  fontWeight:
                    points !== undefined ? '700 !important' : undefined,
                  fontSize: points !== undefined ? '20px !important' : undefined
                }}
                paddingY={2}
                display="flex"
                alignItems="center"
              >
                {t('your_pricing.rate')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{
                  color: '#6A6A6A',
                  fontWeight: '500 !important'
                }}
                justifyContent="flex-end"
                textAlign="right"
                minHeight={50}
                paddingY={2}
                display="flex"
                alignItems="center"
              >
                {rate} %
              </Typography>
            </Grid>
            {points !== undefined && (
              <>
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    height={50}
                    sx={{
                      fontSize: '16px !important',
                      fontWeight: '700'
                    }}
                    display="flex"
                    alignItems="center"
                  >
                    {t('your_pricing.points')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#6A6A6A',
                      fontWeight: '500 !important'
                    }}
                    justifyContent="flex-end"
                    textAlign="right"
                    height={50}
                    display="flex"
                    alignItems="center"
                  >
                    {formatAmount(points)}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={8}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: '16px !important',
                  fontWeight: '700'
                }}
                minHeight={50}
                paddingY={2}
                display="flex"
                alignItems="center"
              >
                {points !== undefined
                  ? t('your_pricing.principal')
                  : t('your_pricing.monthly_payment')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{
                  color: '#6A6A6A',
                  fontWeight: '500 !important'
                }}
                justifyContent="flex-end"
                textAlign="right"
                minHeight={50}
                paddingY={2}
                display="flex"
                alignItems="center"
                whiteSpace="nowrap"
              >
                {formatCurrency(monthlyPayment)}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="subtitle1"
                minHeight={50}
                sx={{
                  fontSize: '16px !important',
                  fontWeight: '700'
                }}
                paddingY={2}
                display="flex"
                alignItems="center"
                whiteSpace="nowrap"
              >
                {t(
                  points !== undefined
                    ? 'your_pricing.long_apr'
                    : 'your_pricing.apr'
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{
                  color: '#6A6A6A',
                  fontWeight: '500 !important'
                }}
                justifyContent="flex-end"
                textAlign="right"
                height={50}
                display="flex"
                alignItems="center"
              >
                {apr} %
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="subtitle1"
                height={50}
                sx={{
                  fontSize: '16px !important',
                  fontWeight: '700'
                }}
                display="flex"
                alignItems="center"
              >
                {t('your_pricing.fees')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{
                  color: '#6A6A6A',
                  fontWeight: '500 !important'
                }}
                justifyContent="flex-end"
                textAlign="right"
                height={50}
                display="flex"
                alignItems="center"
                whiteSpace="nowrap"
              >
                {formatCurrency(fees)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {!!label && (
          <Box
            mt={5.5}
            display="flex"
            justifyContent={points !== undefined ? 'center' : 'flex-end'}
          >
            <FormControlLabel
              sx={{
                mr: 0,
                '& .MuiFormControlLabel-root': {
                  mr: 0
                }
              }}
              value={value}
              control={<Radio />}
              label={
                <Typography
                  sx={{
                    fontSize: '20px !important',
                    fontWeight: '700 !important'
                  }}
                  variant="h6"
                >
                  {' '}
                  {label}
                </Typography>
              }
            />
          </Box>
        )}
      </Box>
    </>
  );
}
