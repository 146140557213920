import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { PrimaryResidencePropertyType } from 'constants/primaryResidenceType';
import Investment from '../../public/icons/investment-prop.svg';
import Prim from '../../public/icons/prim-residence.svg';
import Second from '../../public/icons/second-home.svg';
import { HoveredButton } from './HoveredButton';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

export const usesProperties = [
  {
    label: 'rate-quote.primary_residence',
    data: PrimaryResidencePropertyType.PrimaryResidence,
    pricingValue: 'OO',
    icon: Prim
  },
  {
    label: 'rate-quote.second_home',
    data: PrimaryResidencePropertyType.SecondVacationHome,
    pricingValue: '2ND',
    icon: Second
  },
  {
    label: 'rate-quote.investment_property',
    data: PrimaryResidencePropertyType.InvestmentRental,
    pricingValue: 'INV',
    icon: Investment
  }
] as const;

export function PrimaryUseProperty({ step }) {
  const nav = useNavigate();
  const { t } = useTranslation();

  const { updateOnboardingData, onboardingData } = useOnboarding();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  return (
    <OnboardingModal>
      <OnboardingHeader title={t('rate-quote.property')} />
      <Box
        mx={{
          lg: 14
        }}
      >
        <Grid container>
          {usesProperties.map((val) => (
            <Grid item xs={12} sm key={val.label}>
              <HoveredButton
                active={onboardingData.propertyUse === val.data}
                icon={val.icon}
                label={val.label}
                onClick={() => {
                  updateOnboardingData({ propertyUse: val.data });
                  nav('./property-type');
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </OnboardingModal>
  );
}
