import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Both from '../../public/icons/both.svg';
import Foreclosure from '../../public/icons/foreclosure.svg';
import Multi from '../../public/icons/multi-family.svg';
import Single from '../../public/icons/single-family.svg';
import { HoveredButton } from './HoveredButton';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

const types = [
  {
    label: 'rate-quote.no',
    data: 'no',
    icon: Single,
    route: '../property-address'
  },
  {
    label: 'rate-quote.bankruptcy',
    data: 'multi-family',
    icon: Multi,
    route: '../bankruptcy-long-ago'
  },
  {
    label: 'rate-quote.foreclosure',
    data: 'town-home-condo',
    icon: Foreclosure,
    route: '../foreclosure'
  },
  {
    label: 'rate-quote.both',
    data: 'both',
    icon: Both,
    route: '../bankruptcy-long-ago'
  }
];

export function HaveYouHadBankruptcy({ step }) {
  const nav = useNavigate();

  const { updateOnboardingData, onboardingData } = useOnboarding();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  const { t } = useTranslation();
  return (
    <OnboardingModal>
      <OnboardingHeader title={t('rate-quote.had_bankruptcy')} />
      <Box
        mx={{
          lg: -8
        }}
        pt={4}
      >
        <Grid container>
          {types.map((val) => (
            <Grid item xs={12} sm key={val.label}>
              <HoveredButton
                active={onboardingData.hasBankruptcyOrFloreclosure === val.data}
                icon={val.icon}
                label={val.label}
                onClick={() => {
                  updateOnboardingData({
                    hasBankruptcyOrFloreclosure: val.data
                  });
                  nav(val.route);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </OnboardingModal>
  );
}
