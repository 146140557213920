import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

export function OnboardingHeader({
  title,
  description = undefined,
  mb = undefined,
  marginWithDescription = undefined,
  customContent = null
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      mb={
        mb
          ? mb
          : {
              xs: 4,
              sm: 20
            }
      }
    >
      <Typography variant={isMobile ? 'h5' : 'h4'} textAlign="center">
        {t(title)}
      </Typography>
      {customContent}
      {!!description && (
        <Typography
          mt={marginWithDescription ? marginWithDescription : 4.5}
          variant="h5"
          textAlign="center"
          color="text.secondary"
        >
          {t(description)}
        </Typography>
      )}
    </Box>
  );
}
