import { Loader } from '@googlemaps/js-api-loader';
import { Button, GlobalStyles, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { PurposeType } from '../../services/parsers/purpose';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

export function PropertyAddressO({ step }) {
  const { updateOnboardingData, onboardingData } = useOnboarding();
  const [value, setValue] = useState<any>(onboardingData.address);
  const nav = useNavigate();
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyBeWjtWAhX9MmiRwCLOPak0LoBB85i0zEs'
    });
    loader.importLibrary('places').then(() => {
      const input = ref.current;
      const autoComplete = new google.maps.places.Autocomplete(input, {
        componentRestrictions: {
          country: 'us'
        },
        types: onboardingData.foundHouse ? ['address'] : ['(regions)']
      });
      if (ref.current)
        ref.current.value = onboardingData.foundHouse
          ? onboardingData.address?.name ?? ''
          : onboardingData.address?.address || '';
      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace();
        // Access address components
        const address = place.formatted_address;
        const substringsToRemove = [', EE. UU.', ', USA']; // Array of substrings to remove
        let cleanedAddress = address;
        substringsToRemove.forEach((substring) => {
          const regex = new RegExp(substring, 'g'); // Create a regular expression with global flag
          cleanedAddress = cleanedAddress.replace(regex, ''); // Replace each substring with an empty string
        });
        const streetNumber =
          place.address_components?.find?.((component) =>
            component.types.includes('street_number')
          )?.long_name ?? '';
        const streetName =
          place.address_components?.find?.((component) =>
            component.types.includes('route')
          )?.long_name ?? '';
        const city =
          place.address_components?.find?.((component) =>
            component.types.includes('locality')
          )?.long_name ?? '';
        const state =
          place.address_components?.find?.((component) =>
            component.types.includes('administrative_area_level_1')
          )?.short_name ?? '';
        const stateLongName =
          place.address_components?.find?.((component) =>
            component.types.includes('administrative_area_level_1')
          )?.long_name ?? '';
        const postalCode =
          place.address_components?.find?.((component) =>
            component.types.includes('postal_code')
          )?.long_name ?? '';

        const fullAddress = {
          address: cleanedAddress,
          streetNumber,
          streetName,
          city,
          state,
          stateLongName,
          postalCode,
          name: place.name
        };
        setValue(fullAddress);
      });
    });
  }, [
    onboardingData.address?.address,
    onboardingData.address?.name,
    onboardingData?.foundHouse
  ]);

  return (
    <OnboardingModal maxWidth={1000}>
      <GlobalStyles
        styles={{
          '.pac-container': {
            color: 'blue'
          },
          '.pac-item': {
            cursor: 'pointer'
          }
        }}
      />
      <OnboardingHeader
        mb={{
          xs: 4,
          sm: 13
        }}
        marginWithDescription={7.5}
        title={t(
          onboardingData.purpose === PurposeType.Purchase
            ? onboardingData.foundHouse
              ? 'rate-quote.property_address'
              : 'rate-quote.property_address_alt'
            : 'rate-quote.property_address_refinance'
        )}
        description={t(
          onboardingData.foundHouse
            ? 'rate-quote.property_address_description'
            : 'rate-quote.property_address_description_alt'
        )}
      />
      <TextField
        onChange={() => {
          setValue(undefined);
        }}
        placeholder={t(
          onboardingData.foundHouse
            ? 'rate-quote.property_address_placeholder_'
            : 'rate-quote.property_address_placeholder'
        )}
        inputRef={ref}
        fullWidth
      />
      <Box sx={{ width: 240, mx: 'auto', mt: 14 }}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="success"
          disabled={!value}
          onClick={() => {
            updateOnboardingData({
              address: value
            });
            nav('../name');
          }}
        >
          {t('rate-quote.next')}
        </Button>
      </Box>
    </OnboardingModal>
  );
}
