import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { TextInput } from './TextInput';
import { ICurrencyProps } from './types';

export const format = '(###) ###-####';

export function Phone(props: ICurrencyProps): React.ReactElement {
  const { onChange, name, value, ...rest } = props;

  const onValueChange = useCallback(
    (evt) => {
      onChange && onChange({ target: { name, value: evt.value } });
    },
    [name, onChange]
  );

  return (
    <NumberFormat
      format={format}
      mask="_"
      name={name}
      {...(rest as unknown as any)}
      value={value}
      customInput={TextInput}
      type="text"
      allowLeadingZeros={true}
      onValueChange={onValueChange}
    />
  );
}
