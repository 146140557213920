import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PurposeType } from 'services/parsers/purpose';
import Dollar from '../../public/icons/dollar.svg';
import Purchase from '../../public/icons/home-purchase.svg';
import Refinance from '../../public/icons/home-refinance.svg';
import { HoveredButton } from './HoveredButton';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOfficerContext, useOnboarding } from './Router';

export const steps = [
  {
    icon: Purchase,
    label: 'rate-quote.home_purchase',
    data: PurposeType.Purchase,
    route: '/common/purchase',
    subTitle: ''
  },
  {
    label: 'rate-quote.home_refinance',
    icon: Refinance,
    data: PurposeType.Refinance,
    route: '/common/refinance',
    subTitle: 'rate-quote.home_refinance_subtitle'
  },
  {
    label: 'rate-quote.cashout_refinance',
    icon: Dollar,
    data: PurposeType.CashoutRefinance,
    route: '/common/refinance',
    subTitle: 'rate-quote.cashout_refinance_subtitle'
  }
];

const stepsVa = [
  {
    icon: Purchase,
    label: 'rate-quote.home_purchase',
    data: PurposeType.Purchase,
    route: './purchase',
    subTitle: ''
  },
  {
    label: 'rate-quote.home_refinance',
    icon: Refinance,
    data: PurposeType.Refinance,
    route: './refinance',
    subTitle: ''
  }
];

export function OnboardingSelector({ isVA = false }) {
  const nav = useNavigate();
  const { updateOnboardingData, onboardingData } = useOnboarding();
  const { t } = useTranslation();
  const { officer, referrer } = useOfficerContext();

  return (
    <OnboardingModal>
      <OnboardingHeader
        title="rate-quote.loan_savings_title"
        description="rate-quote.loan_savings_description"
      />
      <Box
        px={{
          lg: 14
        }}
      >
        <Grid container>
          {(isVA ? stepsVa : steps).map((val) => (
            <Grid item xs={12} sm key={val.route}>
              <HoveredButton
                active={
                  val.route === '/va/'
                    ? onboardingData.isVA
                    : val.data === onboardingData.purpose
                }
                icon={val.icon}
                label={t(val.label)}
                subtitle={t(val.subTitle)}
                onClick={() => {
                  if (val.route === '/va/' || isVA) {
                    updateOnboardingData({
                      purpose: val.data,
                      officer,
                      referrer,
                      isVA: true
                    });
                  } else {
                    updateOnboardingData({
                      purpose: val.data,
                      officer,
                      referrer,
                      isVA: false
                    });
                  }
                  nav(val.route);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </OnboardingModal>
  );
}
