import { Button, Slider } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Currency } from 'components/TextInput/Currency';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

export function ApproximateMortgageBalance({ step }) {
  const { updateOnboardingData, onboardingData } = useOnboarding();
  const { t } = useTranslation();
  const [value, setValue] = useState(
    onboardingData.mortgage1 || onboardingData.amount * 0.8
  );
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  const nav = useNavigate();
  return (
    <OnboardingModal>
      <OnboardingHeader title={t('rate-quote.approximate_first_m')} />
      <Currency
        variant="standard"
        onChange={(event) => {
          setValue(event.target.value);
        }}
        isAllowed={({ floatValue }) =>
          floatValue <= onboardingData.amount * 0.8
        }
        onBlur={() => {
          if (value < 80000) {
            setValue(80000);
          }
          if (value > onboardingData.amount * 0.8) {
            setValue(onboardingData.amount * 0.8);
          }
        }}
        hiddenLabel
        InputProps={{
          disableUnderline: true,
          sx: {
            border: '1px solid #1278BC80',
            pt: 1
          }
        }}
        value={
          value >= onboardingData.amount * 0.8
            ? onboardingData.amount * 0.8
            : value
        }
        allowLeadingZeros={false}
        sx={{
          mt: -2,
          mb: 10,
          '& input': {
            textAlign: 'center',
            fontSize: '34px',
            color: 'primary.main'
          }
        }}
      />
      <Slider
        sx={{ mt: 2, mb: 10 }}
        min={80000}
        onChange={(ev, va) => {
          if ((va as number) > onboardingData.amount * 0.8) {
            setValue(onboardingData.amount * 0.8);
          } else {
            setValue(va as number);
          }
        }}
        step={1000}
        value={value}
        max={onboardingData.amount * 0.8}
      />
      <Box sx={{ width: 240, mx: 'auto' }}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="success"
          onClick={() => {
            updateOnboardingData({
              mortgage1: value
            });
            nav('../estimate-credit-rating');
          }}
        >
          {t('rate-quote.next')}
        </Button>
      </Box>
    </OnboardingModal>
  );
}
