import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import No from '../../public/icons/no.svg';
import Yes from '../../public/icons/yes.svg';
import { HoveredButton } from './HoveredButton';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

const usesProperties = [
  {
    label: 'rate-quote.yes',
    data: true,
    icon: Yes
  },
  {
    label: 'rate-quote.no',
    data: false,
    icon: No
  }
];

export function Spouse({ step }) {
  const nav = useNavigate();
  const { t } = useTranslation();

  const { updateOnboardingData } = useOnboarding();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  return (
    <OnboardingModal>
      <Box
        mx={{
          lg: -14
        }}
      >
        <OnboardingHeader title={t('rate-quote.spouse')} />
      </Box>
      <Box
        mx={{
          lg: 37
        }}
        mb={8}
      >
        <Grid container>
          {usesProperties.map((val) => (
            <Grid item xs={12} sm key={val.label}>
              <HoveredButton
                icon={val.icon}
                label={val.label}
                onClick={() => {
                  updateOnboardingData({ veteranSpouse: val.data });
                  nav('../property-address');
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </OnboardingModal>
  );
}
