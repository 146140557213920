import { Box } from '@mui/system';
import React from 'react';

export const OnboardingModal: React.FC<
  React.PropsWithChildren<{
    maxWidth?: number;
    padding?: any;
    mt?: any;
  }>
> = ({ children, maxWidth, padding, mt }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        maxWidth: maxWidth ?? 988,
        mt: mt
          ? mt
          : {
              xs: 10,
              md: 15,
              lg: 20,
              xl: 39
            },
        mx: 'auto',
        borderRadius: '8px',
        position: 'relative',
        padding: padding
          ? padding
          : {
              xs: '45px 30px 64px 30px',
              md: '64px 100px 64px 100px',
              lg: '64px 100px 64px 100px',
              xl: '64px 160px 64px 160px'
            },
        boxShadow: {
          xs: 'none',
          md: '0px 56px 60px -24px rgba(19, 40, 72, 0.08) , 0px 12px 32px -24px rgba(19, 40, 72, 0.04)'
        },
        minHeight: {
          xs: 'calc(100vh - 80px)',
          md: 'auto'
        }
      }}
    >
      {children}
    </Box>
  );
};
