import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { RouterProvider, createMemoryRouter } from 'react-router-dom';
import { IPricing } from 'services/models/IPricing';
import { IPropertyType } from 'services/models/IProspect';
import { PurposeType } from 'services/parsers/purpose';
import { PrimaryResidencePropertyType } from 'constants/primaryResidenceType';
import { ApproximateMortgageBalance } from './ApproximateMortgageBalance';
import { Bankruptcy } from './Bankruptcy';
import { EstimateCreditRating } from './EstimateCreditRating';
import { EstimateCreditRating2 } from './EstimateCreditRating2';
import { Finish } from './Finish';
import { Foreclosure } from './Foreclosure';
import { HaveYouFoundProperty } from './HaveYouFoundProperty';
import { HaveYouHadBankruptcy } from './HaveYouHadBankruptcy';
import { OnboardingLayout } from './OnboardingLayout';
import { OnboardingSelector } from './OnboardingSelector';
import { PersonalInfoEmail } from './PersonalInfoEmail';
import { PersonalInfoName } from './PersonalInfoName';
import { PersonalInfoPhone } from './PersonalInfoPhone';
import { PricingResult } from './PricingResult';
import { PrimaryUseProperty } from './PrimaryUseProperty';
import { ProgressWrapperRoute } from './ProgressWrapperRoute';
import { PropertyAddressO } from './PropertyAddressO';
import { PropertyDownPayment } from './PropertyDownPayment';
import { PropertyType } from './PropertyType';
import { PropertyValue } from './PropertyValue';
import { RealEstateAgent } from './RealEstateAgent';
import { Spouse } from './Spouse';

const onboardingKey = 'onboardingKey';

interface IOnboardingData {
  purpose: PurposeType;
  foundHouse: boolean;
  propertyUse: PrimaryResidencePropertyType;
  amount: number;
  step: number;
  total: number;
  downPayment: number;
  propertyType: IPropertyType;
  mortgage1: number;
  creditRating: 1 | 2 | 3 | 4 | 5;
  negativeItems: 1 | 2 | 3;
  bankruptcy: string;
  foreclosure: string;
  pricingOptions: IPricing[];
  pricingOption: IPricing;
  isVA: boolean;
  hasBankruptcyOrFloreclosure: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: {
    address: string;
    streetNumber: string;
    streetName: string;
    city: string;
    state: string;
    stateLongName: string;
    postalCode: string;
    name: string;
  };
  workingWithRealEstateAgent: boolean;
  veteranSpouse: boolean;
  officer: any;
  referrer: any;
}

const OnboardingContext = createContext<{
  onboardingData: IOnboardingData;
  updateOnboardingData: (x: Partial<IOnboardingData>) => void;
  clearData: () => void;
} | null>(null);

const OfficerContext = createContext<{
  officer: any;
  referrer: any;
} | null>(null);

export const useOnboarding = () => useContext(OnboardingContext);
export const useOfficerContext = () => useContext(OfficerContext);

function PageNotFound() {
  return <>not found</>;
}

/* 
<div suppressHydrationWarning>
{typeof document === 'undefined' ? null : children}
</div> */
export function SafeHydrate({ children }) {
  return (
    <div suppressHydrationWarning>
      {typeof document === 'undefined' ? null : children}
    </div>
  );
}

const updateData = async (newData) => {
  return Promise.resolve().then(() => {
    window.sessionStorage.setItem(onboardingKey, JSON.stringify(newData));
  });
};

export function OnboardingPersist({ children }) {
  const [onboardingData, setOnboardingData] = useState<IOnboardingData>(
    {} as IOnboardingData
  );
  useEffect(() => {
    try {
      const data = window.sessionStorage.getItem(onboardingKey);
      const newData = JSON.parse(data);
      setOnboardingData(newData ? newData : {});
    } catch (error) {
      setOnboardingData({} as IOnboardingData);
    }
  }, []);
  const updateOnboardingData = useCallback(
    (values: Partial<IOnboardingData>) => {
      setOnboardingData((prev) => {
        const newData = {
          ...prev,
          ...values
        };
        updateData(newData);
        return newData;
      });
    },
    []
  );
  const clearData = useCallback(() => {
    const newData: IOnboardingData = {} as IOnboardingData;
    updateData(newData);
    setOnboardingData(newData);
  }, []);

  const value = useMemo(
    () => ({
      onboardingData,
      updateOnboardingData,
      clearData
    }),
    [clearData, onboardingData, updateOnboardingData]
  );
  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
}
export const router =
  typeof document === 'undefined'
    ? undefined
    : createMemoryRouter([
        {
          path: '/:type?',
          element: <OnboardingLayout />,
          children: [
            {
              index: true,
              element: <OnboardingSelector />
            },
            {
              path: 'refinance',
              element: <ProgressWrapperRoute total={12} />,
              children: [
                {
                  index: true,
                  element: <PrimaryUseProperty step={1} />
                },
                {
                  path: 'property-use',
                  element: <PrimaryUseProperty step={2} />
                },
                {
                  path: 'property-type',
                  element: <PropertyType step={3} />
                },
                {
                  path: 'estimated-property-value',
                  element: <PropertyValue step={4} />
                },
                {
                  path: 'approximate-first-mortgage',
                  element: <ApproximateMortgageBalance step={5} />
                },
                {
                  path: 'have-second-mortgage',
                  element: <PropertyType step={6} />
                },
                {
                  path: 'approximate-balance-second-mortgage',
                  element: <PropertyType step={7} />
                },
                {
                  path: 'approximate-cash-out',
                  element: <PropertyType step={8} />
                },
                {
                  path: 'estimate-credit-rating',
                  element: <EstimateCreditRating step={6} />
                },
                {
                  path: 'negative-credit-rating',
                  element: <EstimateCreditRating2 step={10} />
                },
                {
                  path: 'bankruptcy-or-foreclosure',
                  element: <HaveYouHadBankruptcy step={11} />
                },
                {
                  path: 'bankruptcy-long-ago',
                  element: <Bankruptcy step={12} />
                },
                {
                  path: 'foreclosure',
                  element: <Foreclosure step={13} />
                },
                {
                  path: 'spouse',
                  element: <Spouse step={14} />
                },
                {
                  path: 'property-address',
                  element: <PropertyAddressO step={7} />
                },
                {
                  path: 'name',
                  element: <PersonalInfoName step={8} />
                },
                {
                  path: 'email',
                  element: <PersonalInfoEmail step={9} />
                },
                {
                  path: 'phone',
                  element: <PersonalInfoPhone step={10} />
                },
                {
                  path: 'select-pricing',
                  element: <PricingResult step={11} />
                },
                {
                  path: 'result',
                  element: <Finish step={12} />
                }
              ]
            },
            {
              path: 'purchase',
              element: <ProgressWrapperRoute total={12} />,
              children: [
                {
                  index: true,
                  element: <PrimaryUseProperty step={1} />
                },
                {
                  path: 'property-use',
                  element: <PrimaryUseProperty step={1} />
                },
                {
                  path: 'property-type',
                  element: <PropertyType step={2} />
                },
                {
                  path: 'estimated-purchase-price',
                  element: <PropertyValue step={3} />
                },
                {
                  path: 'down-payment',
                  element: <PropertyDownPayment step={4} />
                },
                {
                  path: 'found-home',
                  element: <HaveYouFoundProperty step={5} />
                },
                {
                  path: 'working-real-estate',
                  element: <RealEstateAgent step={6} />
                },
                {
                  path: 'match-real-estate-agent',
                  element: <PropertyType step={7} />
                },
                {
                  path: 'estimate-credit-rating',
                  element: <EstimateCreditRating step={6} />
                },
                {
                  path: 'negative-credit-rating',
                  element: <EstimateCreditRating2 step={9} />
                },
                {
                  path: 'bankruptcy-or-foreclosure',
                  element: <HaveYouHadBankruptcy step={10} />
                },
                {
                  path: 'bankruptcy-long-ago',
                  element: <Bankruptcy step={11} />
                },
                {
                  path: 'foreclosure',
                  element: <Foreclosure step={12} />
                },
                {
                  path: 'spouse',
                  element: <Spouse step={13} />
                },
                {
                  path: 'property-address',
                  element: <PropertyAddressO step={7} />
                },
                {
                  path: 'name',
                  element: <PersonalInfoName step={8} />
                },
                {
                  path: 'email',
                  element: <PersonalInfoEmail step={9} />
                },
                {
                  path: 'phone',
                  element: <PersonalInfoPhone step={10} />
                },
                {
                  path: 'select-pricing',
                  element: <PricingResult step={11} />
                },
                {
                  path: 'result',
                  element: <Finish step={12} />
                }
              ]
            },
            {
              path: '*',
              element: <PageNotFound />
            }
          ]
        }
      ]);

export const ReactRouter = (props) => {
  if (!router) return null;
  return (
    <OfficerContext.Provider value={props}>
      <RouterProvider router={router} />
    </OfficerContext.Provider>
  );
};
