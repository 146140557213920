export const landingPageOptionBStyles = {
  container: {
    px: 0,
    maxWidth: {
      lg: 1290
    }
  },
  wrapper: {
    maxWidth: '1232px',
    margin: '0 auto',
    '& .MuiFilledInput-input': {}
  },
  header: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before': {
      position: 'absolute',
      content: '" "',
      backgroundColor: {
        md: '#003077',
        xs: '#ffff'
      },
      height: '100%',
      left: 'calc(((100vw - (100%)) /2) * -1 )',
      width: '100vw',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: `url(/images/background-login.png)`,
      backgroundPosition: 'left'
    }
  },
  logo: {
    position: 'relative',
    maxWidth: '100%',
    mt: {
      xs: '0',
      md: '25px'
    },
    mb: {
      xs: '75px',
      md: '181px'
    },
    width: '134px',
    height: {
      xs: '108px',
      md: 'auto'
    },
    objectFit: 'contain'
  },
  body: {
    mt: {
      xs: '-60px',
      md: '-150px'
    },
    maxWidth: '100%',
    background: 'white',
    position: 'relative',
    border: '2px solid #E2E3E7',
    borderRadius: '8px',
    boxShadow:
      '0px 12px 32px -24px rgba(19, 40, 72, 0.04), 0px 56px 60px -24px rgba(19, 40, 72, 0.08)',

    mx: {
      xs: '16px',
      md: 'auto'
    },
    px: {
      xs: '30px',
      sm: '90px',
      md: '148px'
    }
  },
  gapContainer: {
    xs: 2,
    md: 7.5
  },
  leftForm: {
    width: {
      xs: '100%',
      sm: '100%',
      md: '351px'
    }
  },
  title: {
    textAlign: {
      xs: 'center',
      sm: 'left'
    },
    width: {
      xs: '100%',
      md: '94%'
    },
    mx: {
      xs: 'auto',
      md: '0'
    },
    mt: {
      xs: 3,
      md: 3.75
    },
    mb: {
      xs: 4,
      md: 2.5
    }
  },
  textInput: {
    mt: {
      xs: 3,
      md: 2.5
    },
    mb: {
      xs: 2,
      md: 2
    }
  },
  button: {
    display: 'block',
    mb: {
      xs: 4,
      md: 2.5
    }
  },
  link: {
    mt: {
      xs: 2.5,
      md: 2.5
    },
    mb: {
      xs: 0,
      md: 5
    }
  },
  verticalLine: {
    width: {
      xs: '100%',
      md: '1px'
    },
    height: {
      xs: '1px',
      md: '80%'
    },
    mt: {
      xs: 0,
      md: 3
    },
    background: '#E2E3E7'
  },
  rightContent: {
    borderRadius: 2,
    mt: {
      xs: 0,
      md: 3.75
    },
    mb: 2
  },
  avatar: {
    width: {
      xs: 64,
      md: 96
    },
    height: {
      xs: 64,
      md: 96
    }
  },
  bottomNmls: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    mr: {
      xs: 3,
      md: 'auto'
    },
    mb: '80px',
    display: {
      xs: 'none',
      md: 'flex'
    }
  },
  image: {
    position: 'relative',
    width: '120px',
    mt: 1
  },
  icon: {
    width: '18px',
    height: '18px',
    marginRight: '10px'
  }
} as const;
