export enum PrimaryResidencePropertyType {
  PrimaryResidence = 'primary-residence',
  FhaSecondaryResidence = 'fha-secondary-residence',
  SecondVacationHome = 'second-vacation-home',
  InvestmentRental = 'investment-rental'
}

export const primaryResidenceTypes = [
  {
    label: PrimaryResidencePropertyType.PrimaryResidence,
    value: PrimaryResidencePropertyType.PrimaryResidence
  },
  {
    label: PrimaryResidencePropertyType.FhaSecondaryResidence,
    value: PrimaryResidencePropertyType.FhaSecondaryResidence
  },
  {
    label: PrimaryResidencePropertyType.SecondVacationHome,
    value: PrimaryResidencePropertyType.SecondVacationHome
  },
  {
    label: PrimaryResidencePropertyType.InvestmentRental,
    value: PrimaryResidencePropertyType.InvestmentRental
  }
];
