import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { setOfficerPrefill } from 'services/actions/storeActions';
import { PurposeType } from 'services/parsers/purpose';
import { useAppDispatch } from 'services/store';
import * as yup from 'yup';
import { Phone } from 'components/TextInput/Phone';
import { parsePricingOptions } from 'components/YourPricing/parsePricingOption';
import { states } from 'constants/states';
import { formatPhoneNumber } from 'utils/commonUtils';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { usesProperties } from './PrimaryUseProperty';
import { useOnboarding } from './Router';
import { getPricings, submitProspect } from './utils';

const mapNegativeItems = {
  Good: 1,
  Average: 2,
  Poor: 3
};

const formSchema = yup.object().shape({
  phone: yup
    .string()
    .matches(
      /^(?!([0-9])\1+$)(?!([2-9]{10}|[2-9]{3}[2-9]{6}[2-9]{1,4}|[2-9]{3}[2-9]{6}[2-9]{1,4}))(?!555)\d{10}$/,
      'Please provide a valid phone number.'
    )
    .min(3)
    .max(40)
    .label('Phone')
    .required('Please provide a valid phone number.')
});

const FICO = {
  1: 741,
  2: 681,
  3: 621,
  4: 581,
  5: 579
};

export function PersonalInfoPhone({ step }) {
  const { t, i18n } = useTranslation();
  const { updateOnboardingData, onboardingData } = useOnboarding();
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);

  return (
    <OnboardingModal maxWidth={1000}>
      <OnboardingHeader
        mb={{
          xs: 4,
          sm: 13
        }}
        marginWithDescription={7.5}
        title={t('rate-quote.phone')}
        description={t('rate-quote.phone_description')}
      />
      <Formik
        initialValues={{
          phone: onboardingData.phone
        }}
        validationSchema={formSchema}
        // eslint-disable-next-line complexity
        onSubmit={async ({ phone }, helpers) => {
          helpers.setSubmitting(true);
          try {
            const occupancy = usesProperties.find(
              (u) => u.data === onboardingData.propertyUse
            );

            if (onboardingData.creditRating === 5) {
              updateOnboardingData({
                phone
              });
              const { data: prospect } = await submitProspect({
                firstName: onboardingData.firstName,
                lastName: onboardingData.lastName,
                language: i18n.language?.toUpperCase?.(),
                downPayment:
                  onboardingData.purpose === 'purchase'
                    ? String(onboardingData.downPayment || '')
                    : String(onboardingData.amount || ''),
                address: {
                  city: onboardingData.address.city,
                  state: onboardingData.address.state,
                  street: onboardingData.address.name,
                  zip: onboardingData.address.postalCode
                    ? onboardingData.address.postalCode
                    : null
                },
                isRefinanceCashOut:
                  onboardingData.purpose === PurposeType.CashoutRefinance,
                propertyType: onboardingData.propertyType,
                purposeType:
                  onboardingData.purpose === PurposeType.CashoutRefinance
                    ? PurposeType.Refinance
                    : onboardingData.purpose,
                residenceOccupationType: onboardingData.propertyUse,
                bankruptcy:
                  onboardingData.bankruptcy !== 'no' &&
                  !!onboardingData.bankruptcy,
                creditRating: null,
                email: onboardingData.email,
                foreclosure:
                  onboardingData.foreclosure !== 'no' &&
                  !!onboardingData.foreclosure,
                isSpouseVA: onboardingData.veteranSpouse,
                isVA: onboardingData.isVA,
                mortgage: String(onboardingData.mortgage1),
                negativeItems:
                  mapNegativeItems[onboardingData.negativeItems] || null,
                phoneNumber: phone,
                pricingOption: null,
                value: String(onboardingData.amount),
                officerEmail: onboardingData.officer?.email ?? null,
                httpReferrer: onboardingData.referrer
              });

              const borrowerInfo = {
                first_name: onboardingData.firstName,
                last_name: onboardingData.lastName,
                phone_primary: formatPhoneNumber(onboardingData.phone),
                state: states.find(
                  (s) =>
                    s.abbreviation.toLowerCase() ===
                      onboardingData.address?.state?.toLowerCase() ||
                    s.name.toLowerCase() ===
                      onboardingData.address?.state?.toLowerCase()
                )?.abbreviation,
                email: onboardingData.email,
                confirm_email: onboardingData.email
              };
              dispatch(
                setOfficerPrefill({
                  officer: onboardingData.officer,
                  formData: borrowerInfo,
                  httpReferrer: onboardingData.referrer,
                  calculator: false,
                  prospectUUID: prospect.uuid
                })
              );
              nav('../result');
              return;
            }
            const { data: pricingResponse } = await getPricings({
              amount:
                onboardingData.purpose === 'purchase'
                  ? onboardingData.amount - onboardingData.downPayment
                  : onboardingData.amount,
              purpose: onboardingData.purpose === 'purchase' ? 0 : 1,
              downPayment: onboardingData.downPayment,
              va: onboardingData.veteranSpouse,
              fico: FICO[onboardingData.creditRating],
              propertyState: onboardingData.address?.state,
              propertyZip: onboardingData.address?.postalCode,
              occupancy: occupancy.pricingValue
            });

            const parsedPricing = parsePricingOptions(
              pricingResponse,
              onboardingData.amount
            );
            if (parsedPricing.length > 0) {
              updateOnboardingData({
                phone,
                pricingOptions: parsedPricing
              });
              nav('../select-pricing');
            } else {
              nav('../result');
            }
            // eslint-disable-next-line no-empty
          } catch (error) {
          } finally {
            helpers.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          submitForm,
          isValid,
          errors,
          isSubmitting
        }) => (
          <>
            <Phone
              variant="outlined"
              placeholder="Phone number"
              size="medium"
              value={values.phone}
              helperText={errors.phone}
              error={!!errors.phone}
              onBlur={handleBlur}
              onChange={(e: any) => {
                handleChange('phone')(e.target.value);
              }}
            />
            <Box sx={{ width: 240, mx: 'auto', mt: 14 }}>
              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                color="success"
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
                onClick={submitForm}
              >
                {t('rate-quote.view_rate')}
              </LoadingButton>
            </Box>
          </>
        )}
      </Formik>
    </OnboardingModal>
  );
}
