import { Button, Slider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Currency } from 'components/TextInput/Currency';
import formatCurrency from '../../utils/formatCurrency';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

export function PropertyDownPayment({ step }) {
  const { updateOnboardingData, onboardingData } = useOnboarding();
  const [value, setValue] = useState(
    onboardingData.downPayment || onboardingData.amount * 0.1
  );
  const { t } = useTranslation();
  const nav = useNavigate();

  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);

  return (
    <OnboardingModal>
      <OnboardingHeader title={t('rate-quote.down_payment')} />
      <Currency
        variant="standard"
        onChange={(event) => {
          setValue(event.target.value);
        }}
        isAllowed={({ floatValue }) => floatValue <= onboardingData.amount}
        suffix={value >= 999999 ? ' +' : undefined}
        hiddenLabel
        value={value >= 999999 ? 1000000 : value}
        allowLeadingZeros={false}
        InputProps={{
          disableUnderline: true,
          sx: {
            border: '1px solid #1278BC80',
            pt: 1
          }
        }}
        sx={{
          mt: -2,
          mb: 10,
          '& input': {
            textAlign: 'center',
            fontSize: '34px',
            color: 'primary.main'
          }
        }}
      />
      <Slider
        sx={{ mt: 2, mb: 2 }}
        min={onboardingData.amount * 0.1}
        onChange={(ev, va) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (va > onboardingData.amount) {
            setValue(onboardingData.amount * 0.9);
          } else {
            setValue(va as number);
          }
        }}
        step={1000}
        value={value}
        max={onboardingData.amount * 0.9}
      />

      <Typography variant="h5" textAlign="center" sx={{ mb: 8 }}>
        {t('rate-quote.amount_', {
          amount: formatCurrency(onboardingData.amount - value)
        })}
      </Typography>
      <Box sx={{ width: 240, mx: 'auto' }}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="success"
          onClick={() => {
            updateOnboardingData({
              downPayment: value
            });
            nav('../found-home');
          }}
        >
          {t('rate-quote.next')}
        </Button>
      </Box>
    </OnboardingModal>
  );
}
