import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import No from '../../public/icons/no.svg';
import Yes from '../../public/icons/yes.svg';
import { HoveredButton } from './HoveredButton';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

const Yes_No = [
  {
    label: 'rate-quote.yes',
    data: true,
    icon: Yes
  },
  {
    label: 'rate-quote.no',
    data: false,
    icon: No
  }
];

export function HaveYouFoundProperty({ step }) {
  const nav = useNavigate();
  const { t } = useTranslation();

  const { updateOnboardingData, onboardingData } = useOnboarding();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  return (
    <OnboardingModal
      padding={{
        xs: '45px 30px 64px 30px',
        md: '64px 100px 64px 100px',
        lg: '64px 100px 64px 100px',
        xl: '64px 100px 93px 100px'
      }}
    >
      <OnboardingHeader title={t('rate-quote.found_home')} />
      <Box
        mx={{
          xl: 51
        }}
      >
        <Grid container>
          {Yes_No.map((val) => (
            <Grid item xs={12} sm key={val.label}>
              <HoveredButton
                active={onboardingData.foundHouse === val.data}
                icon={val.icon}
                label={val.label}
                onClick={() => {
                  updateOnboardingData({ foundHouse: val.data });
                  nav('../estimate-credit-rating');
                  // nav('../working-real-estate');
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </OnboardingModal>
  );
}
