import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Average from '../../public/icons/average.svg';
import BelowAverage from '../../public/icons/below_average.svg';
import Excellent from '../../public/icons/excellent.svg';
import Good from '../../public/icons/good.svg';
import Poor from '../../public/icons/poor.svg';
import { HoveredButton } from './HoveredButton';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

export const ratingTypes = [
  {
    label: 'rate-quote.excellent',
    labelOnly: 'rate-quote.excellent_label',
    color: 'success',
    data: 1,
    icon: Excellent
  },
  {
    label: 'rate-quote.good',
    labelOnly: 'rate-quote.good_label',
    color: 'warning',
    data: 2,
    icon: Good
  },
  {
    label: 'rate-quote.average',
    labelOnly: 'rate-quote.average_label',
    color: 'danger',
    data: 3,
    icon: Average
  },
  {
    label: 'rate-quote.below_average',
    labelOnly: 'rate-quote.below_average_label',
    color: 'error',
    data: 4,
    icon: BelowAverage
  },
  {
    label: 'rate-quote.poor',
    labelOnly: 'rate-quote.poor_label',
    color: 'error',
    data: 5,
    icon: Poor
  }
] as const;

export function EstimateCreditRating({ step }) {
  const nav = useNavigate();

  const { t } = useTranslation();
  const { updateOnboardingData, onboardingData } = useOnboarding();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  return (
    <OnboardingModal>
      <OnboardingHeader
        title={t('rate-quote.estimate_credit_rating')}
        description={t('rate-quote.estimate_credit_rating_description')}
      />
      <Box
        mx={{
          lg: -8
        }}
        mt={-2}
      >
        <Grid container>
          {ratingTypes.map((val) => (
            <Grid item xs={12} sm key={val.label}>
              <HoveredButton
                fixedWidth
                active={val.data === onboardingData.creditRating}
                whiteBackground
                icon={val.icon}
                label={val.label}
                onClick={() => {
                  updateOnboardingData({ creditRating: val.data });
                  if (val.data === 5) {
                    nav('../property-address');
                  } else {
                    nav('../property-address');
                    // nav('../bankruptcy-or-foreclosure');
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </OnboardingModal>
  );
}
