import { IPricing } from 'services/models/IPricing';

const calculateMonthlyPayment = ({ apr, loanAmount, term }) => {
  const totalTerms = 12 * Number(term);
  const EIR = Number(apr) / 100 / 12;
  const monthlyPayment =
    (loanAmount * EIR) / (1 - Math.pow(1 + EIR, -totalTerms));
  return monthlyPayment;
};
const FEES = process.env.NEXT_PUBLIC_PRICING_FEE;

export const parsePricingOptions = (pricings: IPricing[], loanAmount) => {
  return pricings?.map?.((pricing) => {
    const regex = pricing.name.match(/(\d+)/);
    let term = pricing.term;
    if (regex) {
      term = regex[0];
    }
    return {
      monthlyPayment: calculateMonthlyPayment({
        apr: pricing.apr,
        loanAmount,
        term
      }),
      term,
      currentFees: FEES,
      ...pricing
    };
  });
};
