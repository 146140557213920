/* eslint-disable complexity */
import { Box, Typography, Grid, Radio, FormControlLabel } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import formatCurrency, { formatAmount } from 'utils/formatCurrency';

export function SmallRadioBoxPrice({
  innerMt = 3,
  py = 0,
  label = '',
  value,
  title,
  monthlyPayment,
  rate,
  apr,
  fees,
  points = undefined,
  mt = 12
}) {
  const { t } = useTranslation();
  return (
    <>
      <Box
        mt={mt}
        height={'100%'}
        minWidth={303}
        sx={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8
        }}
      >
        <Box
          bgcolor="primary.main"
          sx={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8
          }}
        >
          <Typography
            py={py}
            ml={2}
            sx={{
              fontWeight: '700 !important'
            }}
            textAlign={points !== undefined ? 'center' : 'left'}
            variant="body1"
            fontSize={15}
            color="white"
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            borderColor: '#0B18381F',
            borderTop: 0
          }}
          border={1}
          mt={innerMt}
          px={points !== undefined ? 3 : 6}
        >
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <Typography
                variant="h6"
                fontSize={20}
                sx={{
                  color: '#4CAF50'
                }}
                minHeight={47}
                paddingY={points !== undefined ? 1 : 2}
                display="flex"
                alignItems="center"
              >
                {t('your_pricing.rate')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                justifyContent="flex-end"
                textAlign="right"
                minHeight={47}
                paddingY={points !== undefined ? 1 : 2}
                display="flex"
                alignItems="center"
                sx={{
                  fontSize: '16px !important',
                  fontWeight: '700 !important',
                  color: '#6A6A6A !important'
                }}
              >
                {rate} %
              </Typography>
            </Grid>
            {points !== undefined && (
              <>
                <Grid item xs={8}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: '16px',
                      fontWeight: '700 !important'
                    }}
                    fontSize={15}
                    minHeight={40}
                    display="flex"
                    paddingY={points !== undefined ? 1 : 2}
                    alignItems="center"
                  >
                    {t('your_pricing.points')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '16px !important',
                      fontWeight: '700 !important',
                      color: '#6A6A6A !important'
                    }}
                    justifyContent="flex-end"
                    textAlign="right"
                    minHeight={40}
                    display="flex"
                    paddingY={points !== undefined ? 1 : 2}
                    alignItems="center"
                  >
                    {formatAmount(points)}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={8}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '16px',
                  fontWeight: '700 !important'
                }}
                minHeight={40}
                paddingY={points !== undefined ? 1 : 2}
                display="flex"
                alignItems="center"
              >
                {points !== undefined
                  ? t('your_pricing.principal')
                  : t('your_pricing.monthly_payment')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px !important',
                  fontWeight: '700 !important',
                  color: '#6A6A6A !important'
                }}
                justifyContent="flex-end"
                textAlign="right"
                minHeight={40}
                paddingY={points !== undefined ? 1 : 2}
                display="flex"
                alignItems="center"
                whiteSpace="nowrap"
              >
                {formatCurrency(monthlyPayment)}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '16px',
                  fontWeight: '700 !important'
                }}
                minHeight={40}
                paddingY={points !== undefined ? 1 : 2}
                display="flex"
                alignItems="center"
                whiteSpace="nowrap"
              >
                {t(
                  points !== undefined
                    ? 'your_pricing.long_apr'
                    : 'your_pricing.apr'
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px !important',
                  fontWeight: '700 !important',
                  color: '#6A6A6A !important'
                }}
                minHeight={40}
                justifyContent="flex-end"
                textAlign="right"
                height={points !== undefined ? 20 : 50}
                paddingY={points !== undefined ? 1 : 2}
                display="flex"
                alignItems="center"
              >
                {apr} %
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '16px',
                  fontWeight: '700 !important'
                }}
                minHeight={40}
                paddingY={points !== undefined ? 1 : 2}
                display="flex"
                alignItems="center"
              >
                {t('your_pricing.fees')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '16px !important',
                  fontWeight: '700 !important',
                  color: '#6A6A6A !important'
                }}
                justifyContent="flex-end"
                textAlign="right"
                minHeight={40}
                height={points !== undefined ? 20 : 50}
                paddingY={points !== undefined ? 1 : 2}
                display="flex"
                alignItems="center"
                whiteSpace="nowrap"
              >
                {formatCurrency(fees)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {!!label && (
          <Box
            mt={5.5}
            display="flex"
            justifyContent={points !== undefined ? 'center' : 'flex-end'}
          >
            <FormControlLabel
              sx={{
                mr: 0,
                '& .MuiFormControlLabel-root': {
                  mr: 0
                }
              }}
              value={value}
              control={<Radio />}
              label={
                <Typography variant="h6" fontSize={15}>
                  {' '}
                  {label}
                </Typography>
              }
            />
          </Box>
        )}
      </Box>
    </>
  );
}
