import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { TextInput } from 'components/TextInput/TextInput';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

export function PersonalInfoEmail({ step }) {
  const { updateOnboardingData, onboardingData } = useOnboarding();
  const nav = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  const formik = useFormik({
    initialValues: {
      email: onboardingData.email
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .label('The Email')
        .matches(
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
          'It seems like the email address provided is not valid.'
        )
        .required('Please provide a valid email address ')
    }),
    onSubmit: ({ email }) => {
      updateOnboardingData({
        email
      });
      nav('../phone');
    }
  });

  return (
    <OnboardingModal maxWidth={1000}>
      <OnboardingHeader
        mb={{
          xs: 4,
          sm: 13
        }}
        marginWithDescription={7.5}
        title={t('rate-quote.email_address')}
        description={t('rate-quote.email_address_description')}
      />
      <TextInput
        variant="outlined"
        placeholder="Email Address"
        name="email"
        onChange={formik.handleChange}
        value={formik.values.email}
        helperText={formik.errors.email}
        error={!!formik.errors.email}
        size="medium"
      />
      <Box sx={{ width: 240, mx: 'auto', mt: 14 }}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="success"
          disabled={!formik.isValid}
          onClick={() => formik.handleSubmit()}
        >
          {t('rate-quote.next')}
        </Button>
      </Box>
    </OnboardingModal>
  );
}
