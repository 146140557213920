import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Good from '../../public/icons/term-good.svg';
import Issues from '../../public/icons/term-issues.svg';
import Some from '../../public/icons/term-some.svg';
import { HoveredButton } from './HoveredButton';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

const types = [
  {
    label: 'Not Too Bad',
    data: 1,
    icon: Good
  },
  {
    label: 'Some Issues',
    data: 2,
    icon: Some
  },
  {
    label: 'Major Issues',
    data: 3,
    icon: Issues
  }
] as const;

export function EstimateCreditRating2({ step }) {
  const nav = useNavigate();
  const { t } = useTranslation();

  const { updateOnboardingData, onboardingData } = useOnboarding();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  return (
    <OnboardingModal>
      <OnboardingHeader title={t('rate-quote.negative_items')} />
      <Box
        mx={{
          lg: 12
        }}
      >
        <Grid container>
          {types.map((val) => (
            <Grid item xs={12} sm key={val.label}>
              <HoveredButton
                fixedWidth
                active={val.data === onboardingData.negativeItems}
                whiteBackground
                icon={val.icon}
                label={val.label}
                onClick={() => {
                  updateOnboardingData({ negativeItems: val.data });
                  nav('../property-address');
                  // nav('../bankruptcy-or-foreclosure');
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </OnboardingModal>
  );
}
