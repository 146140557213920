/* eslint-disable complexity */
import { LoadingButton } from '@mui/lab';
import {
  Divider,
  FormControl,
  Grid,
  RadioGroup,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { setOfficerPrefill } from 'services/actions/storeActions';
import { PurposeType } from 'services/parsers/purpose';
import { useAppDispatch } from 'services/store';
import { states } from 'constants/states';
import { formatPhoneNumber } from 'utils/commonUtils';
import formatCurrency from 'utils/formatCurrency';
import { RadioRegularBoxPrice } from '../YourPricing/RadioBoxRegularPrice';
import { ratingTypes } from './EstimateCreditRating';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { steps } from './OnboardingSelector';
import { propertyTypes } from './PropertyType';
import { useOnboarding } from './Router';
import { submitProspect } from './utils';

const FEES = process.env.NEXT_PUBLIC_PRICING_FEE;
const mapNegativeItems = {
  Good: 1,
  Average: 2,
  Poor: 3
};

function DetailSquare({ title, rows }) {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="subtitle2" fontWeight={700} mb={3} textAlign="left">
        {t(title)}
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={3}>
        {rows.map(({ label, value, hide }) =>
          !hide ? (
            <React.Fragment key={label}>
              <Grid item xs={12} zeroMinWidth>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: '12px', color: '#6A6A6A' }}
                  textAlign="left"
                  noWrap
                >
                  {t(label)}:
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      ml: 1,
                      fontSize: '12px',
                      fontWeight: 500,
                      color: 'black'
                    }}
                  >
                    {t(value)}
                  </Typography>
                </Typography>
              </Grid>
            </React.Fragment>
          ) : null
        )}
      </Grid>
    </>
  );
}

export function PricingResult({ step }) {
  const { t, i18n } = useTranslation();
  const nav = useNavigate();
  const [pricing, setPricing] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { updateOnboardingData, onboardingData } = useOnboarding();

  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPricing((event.target as HTMLInputElement).value);
  };
  const dispatch = useAppDispatch();

  const firstrows = [
    {
      label: 'rate-quote.detail_name',
      value: `${onboardingData.firstName} ${onboardingData.lastName}`
    },
    {
      label: 'rate-quote.detail_email',
      value: onboardingData.email
    },
    {
      label: 'rate-quote.detail_phone_number',
      value: formatPhoneNumber(onboardingData.phone)
    }
  ];

  const second_row = [
    {
      label: 'rate-quote.detail_property_type',
      value: propertyTypes.find((p) => p.data === onboardingData.propertyType)
        .label
    },
    {
      label: 'rate-quote.detail_finance',
      value: onboardingData.propertyUse
    },
    {
      label: 'rate-quote.detail_property_state',
      value: onboardingData.address.stateLongName
    },
    {
      label: 'rate-quote.detail_property_address',
      value: onboardingData.address.address
    }
  ];

  const third_row = [
    {
      label: 'rate-quote.detail_property_value',
      value: formatCurrency(onboardingData.amount)
    },
    {
      label: 'rate-quote.detail_down_payment',
      value: formatCurrency(onboardingData.downPayment),
      hide: onboardingData.purpose !== PurposeType.Purchase
    },
    {
      label: 'rate-quote.approximate_first_m',
      value: formatCurrency(onboardingData.mortgage1),
      hide: onboardingData.purpose === PurposeType.Purchase
    },
    {
      label: 'rate-quote.detail_loan_amount',
      value: formatCurrency(
        onboardingData.amount -
          (onboardingData.downPayment || onboardingData.mortgage1)
      ),
      hide: onboardingData.purpose !== PurposeType.Purchase
    },
    {
      label: 'rate-quote.detail_credit_rating',
      value: ratingTypes.find((r) => r.data === onboardingData.creditRating)
        .label
    },
    {
      label: 'rate-quote.loan_purpose',
      value: steps.find((s) => s.data === onboardingData.purpose)?.label
    }
  ];

  const handleCreateLead = async () => {
    try {
      setLoading(true);
      const pricingSelected = onboardingData.pricingOptions.find(
        (v) => pricing === v.name
      );
      const { data: prospect } = await submitProspect({
        firstName: onboardingData.firstName,
        lastName: onboardingData.lastName,
        language: i18n.language?.toUpperCase?.(),
        downPayment:
          onboardingData.purpose === 'purchase'
            ? String(onboardingData.downPayment || '')
            : String(onboardingData.amount || ''),
        address: {
          city: onboardingData.address.city,
          state: onboardingData.address.state,
          street: onboardingData.address.name,
          zip: onboardingData.address.postalCode
            ? onboardingData.address.postalCode
            : null
        },
        isRefinanceCashOut:
          onboardingData.purpose === PurposeType.CashoutRefinance,
        propertyType: onboardingData.propertyType,
        purposeType:
          onboardingData.purpose === PurposeType.CashoutRefinance
            ? PurposeType.Refinance
            : onboardingData.purpose,
        residenceOccupationType: onboardingData.propertyUse,
        bankruptcy:
          onboardingData.bankruptcy !== 'no' && !!onboardingData.bankruptcy,
        creditRating: onboardingData.creditRating,
        email: onboardingData.email,
        foreclosure:
          onboardingData.foreclosure !== 'no' && !!onboardingData.foreclosure,
        isSpouseVA: onboardingData.veteranSpouse,
        isVA: onboardingData.isVA,
        mortgage: String(onboardingData.mortgage1),
        negativeItems: mapNegativeItems[onboardingData.negativeItems] || null,
        phoneNumber: onboardingData.phone,
        pricingOption: pricingSelected,
        value: String(onboardingData.amount),
        officerEmail: onboardingData.officer?.email ?? null,
        httpReferrer: onboardingData.referrer
      });

      const borrowerInfo = {
        first_name: onboardingData.firstName,
        last_name: onboardingData.lastName,
        phone_primary: formatPhoneNumber(onboardingData.phone),
        state: states.find(
          (s) =>
            s.abbreviation.toLowerCase() ===
              onboardingData.address?.state?.toLowerCase() ||
            s.name.toLowerCase() ===
              onboardingData.address?.state?.toLowerCase()
        )?.abbreviation,
        email: onboardingData.email,
        confirm_email: onboardingData.email
      };
      updateOnboardingData({
        pricingOption: pricingSelected
      });
      dispatch(
        setOfficerPrefill({
          officer: onboardingData.officer,
          formData: borrowerInfo,
          httpReferrer: onboardingData.referrer,
          calculator: false,
          prospectUUID: prospect.uuid
        })
      );
      setLoading(false);
      nav('../result', { replace: true });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <OnboardingModal
      maxWidth={1200}
      mt={{
        xs: 10,
        md: 15,
        lg: 20,
        xl: 30
      }}
    >
      <OnboardingHeader
        mb={{
          xs: 4,
          sm: 7
        }}
        title={t('rate-quote.loan_options')}
      />
      <Typography mx={8.75} mb={4} variant="body1" textAlign="center">
        {t('rate-quote.loan_options_description')}
      </Typography>
      <Divider variant="fullWidth" sx={{ my: 8, mx: 8 }} />
      <Box bgcolor={'#1111110A'} p={4} borderRadius={1}>
        <Grid container gap={6}>
          <Grid item xs={12} md zeroMinWidth>
            <DetailSquare
              rows={firstrows}
              title={'rate-quote.personal_information'}
            />
          </Grid>
          <Grid item xs={12} md zeroMinWidth>
            <DetailSquare
              rows={second_row}
              title={'rate-quote.property_details'}
            />
          </Grid>
          <Grid item xs={12} md zeroMinWidth>
            <DetailSquare
              rows={third_row}
              title={'rate-quote.financial_information'}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider variant="fullWidth" sx={{ mt: 8 }} />
      <Box>
        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={pricing}
            onChange={handleChange}
          >
            <Grid container spacing={10}>
              {onboardingData.pricingOptions?.slice?.(0, 3)?.map?.((val) => (
                <Grid item xs={12} md key={val.name}>
                  <RadioRegularBoxPrice
                    apr={val.apr}
                    innerMt={0}
                    py={2}
                    fees={FEES}
                    label={t('your_pricing.select_option')}
                    monthlyPayment={val.monthlyPayment}
                    rate={val.rate}
                    title={val.name}
                    value={val.name}
                    points={val.points || 0.0}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ width: 240, mx: 'auto', mt: 14 }}>
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          color="success"
          loading={loading}
          disabled={!pricing}
          onClick={handleCreateLead}
        >
          {t('rate-quote.next')}
        </LoadingButton>
      </Box>
    </OnboardingModal>
  );
}
