export const marks = [
  {
    value: 7500,
    label: '$10k or less'
  },
  {
    value: 12500,
    label: '$10k — $15k'
  },
  {
    value: 17500,
    label: '$15k — $20k'
  },
  {
    value: 22500,
    label: '$20k — $25k'
  },
  {
    value: 27500,
    label: '$25k — $30k'
  },
  {
    value: 32500,
    label: '$30k — $35k'
  },
  {
    value: 37500,
    label: '$35k — $40k'
  },
  {
    value: 42500,
    label: '$40k — $45k'
  },
  {
    value: 47500,
    label: '$45k — $50k'
  },
  {
    value: 52500,
    label: '$50k — $55k'
  },
  {
    value: 57500,
    label: '$55k — $60k'
  },
  {
    value: 62500,
    label: '$60k — $65k'
  },
  {
    value: 67500,
    label: '$65k — $70k'
  },
  {
    value: 72500,
    label: '$70k — $75k'
  },
  {
    value: 77500,
    label: '$75k — $80k'
  },
  {
    value: 82500,
    label: '$80k — $85k'
  },
  {
    value: 87500,
    label: '$85k — $90k'
  },
  {
    value: 92500,
    label: '$90k — $95k'
  },
  {
    value: 97500,
    label: '$95k — $100k'
  },
  {
    value: 102500,
    label: '$100k — $105k'
  },
  {
    value: 107500,
    label: '$105k — $110k'
  },
  {
    value: 112500,
    label: '$110k — $115k'
  },
  {
    value: 117500,
    label: '$115k — $120k'
  },
  {
    value: 122500,
    label: '$120k — $125k'
  },
  {
    value: 127500,
    label: '$125k — $130k'
  },
  {
    value: 132500,
    label: '$130k — $135k'
  },
  {
    value: 137500,
    label: '$135k — $140k'
  },
  {
    value: 142500,
    label: '$140k — $145k'
  },
  {
    value: 147500,
    label: '$145k — $150k'
  },
  {
    value: 152500,
    label: '$150k — $155k'
  },
  {
    value: 157500,
    label: '$155k — $160k'
  },
  {
    value: 162500,
    label: '$160k — $165k'
  },
  {
    value: 167500,
    label: '$165k — $170k'
  },
  {
    value: 172500,
    label: '$170k — $175k'
  },
  {
    value: 177500,
    label: '$175k — $180k'
  },
  {
    value: 182500,
    label: '$180k — $185k'
  },
  {
    value: 187500,
    label: '$185k — $190k'
  },
  {
    value: 192500,
    label: '$190k — $195k'
  },
  {
    value: 197500,
    label: '$195k — $200k'
  },
  {
    value: 205000,
    label: '$200k — $210k'
  },
  {
    value: 215000,
    label: '$210k — $220k'
  },
  {
    value: 225000,
    label: '$220k — $230k'
  },
  {
    value: 235000,
    label: '$230k — $240k'
  },
  {
    value: 245000,
    label: '$240k — $250k'
  },
  {
    value: 255000,
    label: '$250k — $260k'
  },
  {
    value: 265000,
    label: '$260k — $270k'
  },
  {
    value: 275000,
    label: '$270k — $280k'
  },
  {
    value: 285000,
    label: '$280k — $290k'
  },
  {
    value: 295000,
    label: '$290k — $300k'
  },
  {
    value: 305000,
    label: '$300k — $310k'
  },
  {
    value: 315000,
    label: '$310k — $320k'
  },
  {
    value: 325000,
    label: '$320k — $330k'
  },
  {
    value: 335000,
    label: '$330k — $340k'
  },
  {
    value: 345000,
    label: '$340k — $350k'
  },
  {
    value: 355000,
    label: '$350k — $360k'
  },
  {
    value: 365000,
    label: '$360k — $370k'
  },
  {
    value: 375000,
    label: '$370k — $380k'
  },
  {
    value: 385000,
    label: '$380k — $390k'
  },
  {
    value: 395000,
    label: '$390k — $400k'
  },
  {
    value: 410000,
    label: '$400k — $420k'
  },
  {
    value: 430000,
    label: '$420k — $440k'
  },
  {
    value: 450000,
    label: '$440k — $460k'
  },
  {
    value: 470000,
    label: '$460k — $480k'
  },
  {
    value: 490000,
    label: '$480k — $500k'
  },
  {
    value: 510000,
    label: '$500k — $520k'
  },
  {
    value: 530000,
    label: '$520k — $540k'
  },
  {
    value: 550000,
    label: '$540k — $560k'
  },
  {
    value: 570000,
    label: '$560k — $580k'
  },
  {
    value: 590000,
    label: '$580k — $600k'
  },
  {
    value: 610000,
    label: '$600k — $620k'
  },
  {
    value: 630000,
    label: '$620k — $640k'
  },
  {
    value: 650000,
    label: '$640k — $660k'
  },
  {
    value: 670000,
    label: '$660k — $680k'
  },
  {
    value: 690000,
    label: '$680k — $700k'
  },
  {
    value: 710000,
    label: '$700k — $720k'
  },
  {
    value: 730000,
    label: '$720k — $740k'
  },
  {
    value: 750000,
    label: '$740k — $760k'
  },
  {
    value: 770000,
    label: '$760k — $780k'
  },
  {
    value: 790000,
    label: '$780k — $800k'
  },
  {
    value: 810000,
    label: '$800k — $820k'
  },
  {
    value: 830000,
    label: '$820k — $840k'
  },
  {
    value: 850000,
    label: '$840k — $860k'
  },
  {
    value: 870000,
    label: '$860k — $880k'
  },
  {
    value: 890000,
    label: '$880k — $900k'
  },
  {
    value: 910000,
    label: '$900k — $920k'
  },
  {
    value: 930000,
    label: '$920k — $940k'
  },
  {
    value: 950000,
    label: '$940k — $960k'
  },
  {
    value: 970000,
    label: '$960k — $980k'
  },
  {
    value: 990000,
    label: '$980k — $1kk'
  },
  {
    value: 1050000,
    label: '$1M — $1,1M'
  },
  {
    value: 1150000,
    label: '$1,1M — $1,2M'
  },
  {
    value: 1250000,
    label: '$1,2M — $1,3M'
  },
  {
    value: 1350000,
    label: '$1,3M — $1,4M'
  },
  {
    value: 1450000,
    label: '$1,4M — $1,5M'
  },
  {
    value: 1550000,
    label: '$1,5M — $1,6M'
  },
  {
    value: 1650000,
    label: '$1,6M — $1,7M'
  },
  {
    value: 1750000,
    label: '$1,7M — $1,8M'
  },
  {
    value: 1850000,
    label: '$1,8M — $1,9M'
  },
  {
    value: 1950000,
    label: '$1,9M — $2M'
  },
  {
    value: 2000000,
    label: 'over $2 million'
  }
];

export const foreclosures = [
  {
    value: 'no',
    label: 'rate-quote.never_foreclosure'
  },
  {
    value: 'yes',
    label: 'rate-quote.6_7_years'
  },
  {
    value: '6_over',
    label: 'rate-quote.5_6_years'
  },
  {
    value: '5_yr',
    label: 'rate-quote.4_5_years'
  },
  {
    value: '4_yr',
    label: 'rate-quote.3_4_years'
  },
  {
    value: '3_yr',
    label: 'rate-quote.2_3_years'
  },
  {
    value: '2_yr',
    label: 'rate-quote.1_2_years'
  },
  {
    value: '1_yr',
    label: 'rate-quote.less_year'
  },
  {
    value: 'under_1',
    label: 'rate-quote.currently_foreclosure'
  }
];

export const bankruptcy = [
  {
    value: 'no',
    label: 'rate-quote.never_bankruptcy'
  },
  {
    value: 'yes',
    label: 'rate-quote.6_7_years'
  },
  {
    value: '6_over',
    label: 'rate-quote.5_6_years'
  },
  {
    value: '5_yr',
    label: 'rate-quote.4_5_years'
  },
  {
    value: '4_yr',
    label: 'rate-quote.3_4_years'
  },
  {
    value: '3_yr',
    label: 'rate-quote.2_3_years'
  },
  {
    value: '2_yr',
    label: 'rate-quote.1_2_years'
  },
  {
    value: '1_yr',
    label: 'rate-quote.less_year'
  },
  {
    value: 'under_1',
    label: 'rate-quote.currently_bankruptcy'
  }
];
