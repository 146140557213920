import { Check, Language, Mail, PhoneInTalk } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Grid,
  Typography,
  Link as LinkMui
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import Router from 'next/router';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { stepRoutes } from 'constants/step-routes';
import { landingPageOptionBStyles } from 'styles/landingPageOptionBStyles';
import { formatUriPhoneNumber, formatPhoneNumber } from 'utils/commonUtils';
import { SmallRadioBoxPrice } from '../YourPricing/SmallRadioBoxPrice';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOfficerContext, useOnboarding } from './Router';

const FEES = process.env.NEXT_PUBLIC_PRICING_FEE;

// eslint-disable-next-line complexity
export function Finish({ step }) {
  const nav = useNavigate();
  const { t } = useTranslation();
  const [isFinishing, setIsFinishing] = useState(false);
  const { updateOnboardingData, onboardingData, clearData } = useOnboarding();
  const { officer } = useOfficerContext();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  useEffect(() => {
    if (
      !onboardingData.pricingOptions &&
      !isFinishing &&
      onboardingData.creditRating !== 5
    ) {
      nav('/');
    }
  }, [isFinishing, nav, onboardingData]);
  const branchNMLS = officer?.officerInfo?.branches?.[0]?.nmls;
  const isCreditLow = onboardingData.creditRating === 5;
  return (
    <OnboardingModal maxWidth={1227}>
      <Box
        sx={{
          mb: 7,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Check color="primary" sx={{ fontSize: 102 }} />
      </Box>
      <OnboardingHeader
        mb={{
          xs: 4,
          sm: 4
        }}
        marginWithDescription={8}
        title={t(
          isCreditLow ? 'rate-quote.thanks' : 'rate-quote.request_complete'
        )}
        description={t(
          isCreditLow
            ? 'rate-quote.thanks_description'
            : 'rate-quote.request_complete_description'
        )}
        customContent={
          <Box sx={{ width: 240, mx: 'auto', mt: 7 }}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="success"
              onClick={() => {
                setIsFinishing(true);
                setTimeout(() => {
                  clearData();
                  Router.push(stepRoutes.SIGN_UP.route);
                }, 200);
              }}
            >
              {t('rate-quote.apply_now')}
            </Button>
          </Box>
        }
      />
      <Box>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: 'auto',
            md: 'auto auto auto'
          }}
          maxWidth="100%"
          gridTemplateRows="1fr"
          gap={6}
          alignItems="center"
          justifyContent="center"
        >
          <Box height="100%">
            {officer && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}
              >
                <Box
                  sx={{
                    border: 1,
                    borderColor: '#E2E3E7',
                    borderRadius: 2,
                    p: 4,
                    height: '100%',
                    width: {
                      xs: '100%',
                      md: 393
                    },
                    maxWidth: '100%'
                  }}
                >
                  <Typography variant="subtitle2" mb={6} color="text.secondary">
                    {t('rate-quote.loan_officer').toUpperCase()}
                  </Typography>
                  <Grid container spacing={6}>
                    <Grid item xs="auto">
                      <Avatar
                        src={
                          officer.profilePicture || '/images/avatar-default.svg'
                        }
                        sx={landingPageOptionBStyles.avatar}
                      ></Avatar>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography variant="h6" color="#0B1838">
                        {`${officer.firstName} ${officer.lastName}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        mb={branchNMLS ? 0 : 2}
                        noWrap
                      >
                        {t('nmls')} #{officer.officerInfo.nmls}
                      </Typography>
                      {branchNMLS && (
                        <Typography
                          noWrap
                          variant="body2"
                          color="text.secondary"
                        >
                          {t('branch_nmls')} #{branchNMLS}
                        </Typography>
                      )}
                      {officer?.officerInfo?.urlName && (
                        <LinkMui
                          href={officer?.officerInfo?.urlName}
                          underline="none"
                          target="_blank"
                        >
                          <Typography
                            display="flex"
                            alignItems="center"
                            variant="body2"
                            mt={1}
                            noWrap
                          >
                            <Language
                              fontSize="small"
                              color="primary"
                              sx={landingPageOptionBStyles.icon}
                            />
                            {t('visit_site')}
                          </Typography>
                        </LinkMui>
                      )}
                      <LinkMui
                        href={`mailto:${officer.email}`}
                        underline="none"
                      >
                        <Typography
                          display="flex"
                          alignItems="center"
                          variant="body2"
                          mt={1}
                          noWrap
                        >
                          <Mail
                            sx={landingPageOptionBStyles.icon}
                            color="primary"
                          />
                          {officer.email}
                        </Typography>
                      </LinkMui>
                      <LinkMui
                        href={formatUriPhoneNumber(officer.primaryPhone)}
                        underline="none"
                      >
                        <Typography
                          display="flex"
                          alignItems="center"
                          variant="body2"
                          mt={1}
                        >
                          <PhoneInTalk
                            sx={landingPageOptionBStyles.icon}
                            color="primary"
                          />
                          {formatPhoneNumber(officer.primaryPhone ?? '')}
                        </Typography>
                      </LinkMui>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
          {!!onboardingData.pricingOption && (
            <>
              <Box height="100%" width="1px" bgcolor="#0B18381F" />
              <Box height={'100%'} mx="auto">
                <Box width={303} height={'100%'}>
                  <SmallRadioBoxPrice
                    mt={0}
                    apr={onboardingData.pricingOption.apr}
                    innerMt={0}
                    py={2}
                    fees={FEES}
                    monthlyPayment={onboardingData.pricingOption.monthlyPayment}
                    rate={onboardingData.pricingOption.rate}
                    title={onboardingData.pricingOption.name}
                    value={onboardingData.pricingOption.name}
                    points={onboardingData.pricingOption.points || 0.0}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Box sx={{ width: 240, mx: 'auto', mt: 7 }}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="success"
          onClick={() => {
            setIsFinishing(true);
            setTimeout(() => {
              clearData();
              Router.push(stepRoutes.SIGN_UP.route);
            }, 200);
          }}
        >
          {t('rate-quote.apply_now')}
        </Button>
      </Box>
    </OnboardingModal>
  );
}
