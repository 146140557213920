import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { IPropertyType } from 'services/models/IProspect';
import Condo from '../../public/icons/condo.svg';
import Multi from '../../public/icons/multi-family.svg';
import Single from '../../public/icons/single-family.svg';
import { HoveredButton } from './HoveredButton';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

export const propertyTypes = [
  {
    label: 'rate-quote.single_family',
    data: IPropertyType.SingleFamilyResidence,
    icon: Single
  },
  {
    label: 'rate-quote.multi_family',
    data: IPropertyType.MultiFamilyResidence,
    icon: Multi
  },
  {
    label: 'rate-quote.town_home',
    data: IPropertyType.Condominium,
    icon: Condo
  }
];

export function PropertyType({ step }) {
  const nav = useNavigate();
  const { t } = useTranslation();

  const { updateOnboardingData, onboardingData } = useOnboarding();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  return (
    <OnboardingModal>
      <OnboardingHeader title={t('rate-quote.property_type')} />
      <Box
        mx={{
          lg: -8.75
        }}
      >
        <Grid container>
          {propertyTypes.map((val) => (
            <Grid item xs={12} sm key={val.label}>
              <HoveredButton
                active={onboardingData.propertyType === val.data}
                icon={val.icon}
                label={val.label}
                onClick={() => {
                  updateOnboardingData({ propertyType: val.data });
                  if (onboardingData.purpose === 'purchase') {
                    nav('../estimated-purchase-price');
                  } else {
                    nav('../estimated-property-value');
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </OnboardingModal>
  );
}
