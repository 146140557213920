import { Button, Slider } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Currency } from 'components/TextInput/Currency';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';

export function PropertyValue({ step }) {
  const { updateOnboardingData, onboardingData } = useOnboarding();
  const [value, setValue] = useState(onboardingData.amount || 300000);
  const nav = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);

  return (
    <OnboardingModal>
      <OnboardingHeader title={t('rate-quote.property_value')} />
      <Currency
        variant="standard"
        onChange={(event) => {
          setValue(event.target.value);
        }}
        isAllowed={({ floatValue }) => floatValue <= 1000000}
        onBlur={() => {
          if (value < 100000) {
            setValue(100000);
          }
          if (value > 999999) {
            setValue(999999.99);
          }
        }}
        suffix={value >= 999999 ? ' +' : undefined}
        hiddenLabel
        value={value >= 999999 ? 1000000 : value}
        allowLeadingZeros={false}
        InputProps={{
          disableUnderline: true,
          sx: {
            border: '1px solid #1278BC80',
            pt: 1
          }
        }}
        sx={{
          mt: -2,
          mb: 10,
          '& input': {
            textAlign: 'center',
            fontSize: '34px',
            color: 'primary.main'
          }
        }}
      />
      <Slider
        sx={{ mt: 2, mb: 10 }}
        min={100000}
        onChange={(ev, va) => {
          if (va > 999999) {
            setValue(999999.99);
          } else {
            setValue(va as number);
          }
        }}
        step={1000}
        value={value}
        max={1000000}
      />
      <Box sx={{ width: 240, mx: 'auto' }}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="success"
          onClick={() => {
            updateOnboardingData({
              amount: value
            });
            if (onboardingData.purpose === 'purchase') {
              nav('../down-payment');
            } else {
              nav('../approximate-first-mortgage');
            }
          }}
        >
          {t('rate-quote.next')}
        </Button>
      </Box>
    </OnboardingModal>
  );
}
