import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';

export function HoveredButton({
  // active = false,
  whiteBackground = false,
  fixedWidth = false,
  onClick,
  ...rest
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      {...rest}
    >
      <Box
        sx={{
          cursor: 'pointer',
          width: {
            xs: 300,
            sm: 'auto'
          },
          display: {
            xs: 'flex',
            sm: 'block'
          },
          spacing: 5,
          my: {
            xs: 2,
            sm: 'auto'
          },
          alignItems: 'center'
        }}
        onClick={onClick}
      >
        <Box
          sx={[
            {
              cursor: 'pointer',
              width: {
                xs: 80,
                sm: 105
              },
              height: {
                xs: 80,
                sm: 105
              },
              backgroundColor: whiteBackground
                ? '#ffffff'
                : 'rgba(240, 213, 103, 1)',
              borderColor: whiteBackground
                ? 'rgba(237, 240, 246, 1)'
                : 'rgba(171, 138, 5, 1)',
              borderWidth: 2,
              borderStyle: 'solid',
              borderRadius: '100%',
              display: 'flex',
              mx: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
              mb: {
                xs: 0,
                md: 7
              },
              '&:hover': {
                borderColor: whiteBackground
                  ? 'rgba(222, 193, 73, 1)'
                  : 'rgba(171, 138, 5, 1)',
                backgroundColor: '#ffffff'
              }
            } /* ,
            active && {
              borderColor: whiteBackground
                ? 'rgba(222, 193, 73, 1)'
                : 'rgba(171, 138, 5, 1)',
              backgroundColor: '#ffffff'
            } */
          ]}
        >
          <Box
            sx={{
              width: '100%',
              p: {
                xs: 0,
                sm: 0
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <rest.icon></rest.icon>
          </Box>
        </Box>
        <Box width={fixedWidth ? 105 : undefined} flex={1}>
          <Typography whiteSpace="pre" textAlign="center" variant="subtitle1">
            {t(rest.label)}
          </Typography>
          {!!rest.subtitle && (
            <Typography
              whiteSpace="pre"
              textAlign="center"
              variant="body2"
              sx={{ fontSize: 12 }}
            >
              {t(rest.subtitle)}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
