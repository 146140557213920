import { PurposeType } from 'services/parsers/purpose';
import { PrimaryResidencePropertyType } from 'constants/primaryResidenceType';
import { IPaginated } from './IPaginated';
import { IPricing } from './IPricing';

export enum IPropertyType {
  SingleFamilyResidence = 'single-family-residence',
  MultiFamilyResidence = 'multi-family-residence',
  Condominium = 'condominium',
  Cooperative = 'cooperative',
  TwoUnitProperty = '2-unit-property',
  ThreeUnitProperty = '3-unit-property',
  FourUnitProperty = '4-unit-property',
  Townhouse = 'townhouse',
  Manufactured = 'manufactured',
  Land = 'land',
  PlannedUnitDevelopment = 'planned-unit-development',
  Commercial = 'commercial'
}
interface IAddress {
  street: string;
  city: string;
  state: string;
  zip: string;
}

export interface IProspect {
  purposeType: PurposeType;
  residenceOccupationType: PrimaryResidencePropertyType;
  propertyType: IPropertyType;
  value?: string;
  address: IAddress;
  mortgage?: string;
  bankruptcy?: boolean;
  foreclosure?: boolean;
  isVA?: boolean;
  creditRating?: 1 | 2 | 3 | 4 | 5;
  negativeItems?: 1 | 2 | 3;
  pricingOption?: IPricing;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  uuid?: string;
  officerEmail?: string;
  httpReferrer?: string;
  isSpouseVA?: boolean;
  downPayment?: number;
  loan?: any;
  createdAt?: string;
  officer?: any;
}

export type IProspectPaginated = IPaginated<IProspect>;
