import { Button, Slider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingModal } from './OnboardingModal';
import { useOnboarding } from './Router';
import { foreclosures } from './constants';

function valueText(value: number) {
  return `$ ${value}`;
}
export function Foreclosure({ step }) {
  const { t } = useTranslation();
  const { updateOnboardingData, onboardingData } = useOnboarding();
  const defaultValue = foreclosures.findIndex(
    (m) => onboardingData.foreclosure === m.value
  );
  const [index, setIndex] = useState(defaultValue === -1 ? 1 : defaultValue);
  const nav = useNavigate();
  useEffect(() => {
    updateOnboardingData({
      step
    });
  }, [step, updateOnboardingData]);
  return (
    <OnboardingModal>
      <OnboardingHeader title={t('rate-quote.long_ago_foreclosure')} />
      <Typography mb={10} variant="h4" textAlign="center" color="primary">
        {foreclosures[index]?.label}
      </Typography>
      <Slider
        sx={{ mt: 2, mb: 10 }}
        onChange={(ev, va) => {
          setIndex(va as number);
        }}
        min={0}
        max={8}
        step={1}
        value={index}
        getAriaValueText={valueText}
      />
      <Box sx={{ width: 240, mx: 'auto' }}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="success"
          onClick={() => {
            updateOnboardingData({
              foreclosure: foreclosures[index].value
            });
            nav('../property-address');
          }}
        >
          {t('rate-quote.next')}
        </Button>
      </Box>
    </OnboardingModal>
  );
}
